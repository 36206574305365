<template>
  <ion-menu menu-id="app-menu" side="start" type="reveal" class="app-sidemenu">
    <!--
    <ion-header>
      <ion-toolbar color="primary">
        <ion-title>Menu</ion-title>
      </ion-toolbar>
      <ion-list>
        <ion-item button @click="menuNavigation('/home')">
          <ion-label>{{ user?.email }}</ion-label>
        </ion-item>
      </ion-list>
    </ion-header>
    -->
    <ion-header>
      <ion-toolbar>
        <ion-row class="user-details-wrapper">
          <ion-col size="4">
            <ion-img src="assets/shapes.svg"></ion-img>
          </ion-col>
          <ion-col class="user-info-wrapper" size="8">
            <h3 class="user-name">uTwist NFT</h3>
            <h5 class="user-handle">{{ user?.email }}</h5>
          </ion-col>
        </ion-row>
        <ion-row class="user-stats-wrapper user-details-wrapper">
          <ion-col>
            <span class="user-stat-value">Version</span>
            <span class="user-stat-name">1.0</span>
          </ion-col>
          <ion-col>
            <span class="user-stat-value">537</span>
            <span class="user-stat-name">Followers</span>
          </ion-col>
        </ion-row>
      </ion-toolbar>
    </ion-header>
    <ion-content>
      <ion-list>
        <ion-item button router-link="/home">
          <ion-label>Home</ion-label>
        </ion-item>
        <ion-item button @click="menuNavigation('/about')">
          <ion-label>About</ion-label>
        </ion-item>
        <ion-item button @click="menuNavigation('/register')">
          <ion-label>Register</ion-label>
        </ion-item>
        <ion-item button @click="menuNavigation('/login')">
          <ion-label>Login</ion-label>
        </ion-item>
        <ion-item button @click="logout()">
          <ion-label>Logout</ion-label>
        </ion-item>
      </ion-list>
    </ion-content>
  </ion-menu>
</template>
<script>
import { supabase } from "../supabase";
import { useIonRouter } from "@ionic/vue";
import { defineComponent, ref } from "vue";
import { store } from "../store";
import {
  IonMenu,
  IonHeader,
  IonContent,
  IonToolbar,
  IonImg,
  IonCol,
  IonRow,
  IonItem,
  IonList,
  IonLabel,
  menuController,
  toastController,
  loadingController
} from "@ionic/vue";

export default defineComponent({
  name: "app-menu",
  components: {
    IonMenu,
    IonHeader,
    IonContent,
    IonToolbar,
    IonCol,
    IonImg,
    IonRow,
    IonItem,
    IonList,
    IonLabel
  },
  setup() {
    /*
    const session = ref(supabase.auth.session());
    const { t } = useI18n({
      inheritLocale: true
    });
*/

    const ionRouter = useIonRouter();

    const menuNavigation = async url => {
      menuController.close("app-menu");
      ionRouter.push(url);
    };

    const user = ref(store.user);

    const logout = async () => {
      const loader = await loadingController.create({});
      const toast = await toastController.create({ duration: 5000 });

      try {
        await menuController.close("app-menu");
        await loader.present();
        const { error } = await supabase.auth.signOut();

        if (error) throw error;

        store.user = {};

        toast.message = "Logout Effettuato";
        await toast.present();
      } catch (error) {
        toast.message = error.error_description || error.message;
        await toast.present();
      } finally {
        await loader.dismiss();
        //
      }

      //await menuController.close("app-menu");
    };

    /*
  methods: {
    menuNavigation(url) {
      menuController.close("app-menu");
      this.$router.push(url);
    },
    logout() {
      supabase.auth.signOut();
      menuController.close("app-menu");
      this.$router.push("/login");
    }
  }
  */

    return { menuNavigation, logout, user };
  }
});
</script>

<style lang="scss" scoped>
toolbar-menu {
  font-weight: 600;
  background: #000000;
}

:host {
  --page-margin: var(--app-fair-margin);
  --page-background: var(--app-background-alt-shade);

  --page-headers-shadow-color: rgba(var(--ion-color-darkest-rgb), 0.4);
}

.app-sidemenu {
  ion-toolbar {
    --padding-start: 0px;
    --padding-end: 0px;
    --padding-top: 0px;
    --padding-bottom: 0px;
    --xbackground: var(--ion-color-dark-tint);
  }

  ion-content {
    --background: var(--page-background);
  }

  .user-details-wrapper {
    --ion-grid-column-padding: 0px;

    // background-color: rgba(var(--ion-color-light-rgb), 0.15);
    color: var(--ion-color-lightest);
    padding: var(--page-margin);
    align-items: center;

    & + .user-details-wrapper {
      padding-top: 0px;
    }
  }

  .user-info-wrapper {
    padding-left: var(--page-margin);

    .user-name {
      margin: 0px 0px 5px;
    }

    .user-handle {
      color: var(--ion-color-light-shade);
      margin: 0px;
      font-size: 14px;
      font-weight: 400;
    }
  }

  .user-stats-wrapper {
    text-align: center;

    .user-stat-value {
      margin-right: 5px;
      font-weight: 500;
    }

    .user-stat-name {
      color: var(--ion-color-light-shade);
      font-size: 14px;
    }
  }
}
</style>