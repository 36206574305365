<template>
  <ion-page>
    <ion-content padding="true" scroll="false">
      <form @submit.prevent="handleSignup" novalidate>
        <ion-row>
          <ion-col class="login-content">
            <div class="ion-padding">
              <h1>uTwist NFT</h1>
              <p>{{ t('signup.main') }}</p>
            </div>
            <ion-list>
              <ion-item>
                <ion-icon :icon="mailIcon"></ion-icon>
                <ion-input
                  type="email"
                  name="emailAddress"
                  placeholder="Login Email"
                  v-model="vv.emailAddress.$model"
                />
              </ion-item>
              <div class="error-container" v-if="vv.emailAddress.$invalid">
                <div class="error-message">
                  <ion-icon :icon="alertIcon"></ion-icon>
                  <span>{{ vv?.emailAddress?.$errors[0]?.$message }}</span>
                </div>
              </div>
              <ion-item>
                <ion-icon :icon="lockIcon"></ion-icon>
                <ion-input
                  type="password"
                  name="password1"
                  placeholder="Password"
                  v-model="vv.password1.$model"
                />
              </ion-item>
              <div class="error-container" v-if="vv.password1.$invalid">
                <div class="error-message">
                  <ion-icon :icon="alertIcon"></ion-icon>
                  <span>{{ vv?.password1?.$errors[0]?.$message }}</span>
                </div>
              </div>
              <ion-item>
                <ion-icon :icon="lockIcon"></ion-icon>
                <ion-input
                  type="password"
                  name="password2"
                  placeholder="Password"
                  v-model="vv.password2.$model"
                />
              </ion-item>
              <div class="error-container" v-if="vv.password2.$invalid">
                <div class="error-message">
                  <ion-icon :icon="alertIcon"></ion-icon>
                  <span>{{ vv?.password2?.$errors[0]?.$message }}</span>
                </div>
              </div>
            </ion-list>

            <div class="other-auth-options-row">
              <ion-row>
                <ion-col class="floating"></ion-col>
                <ion-col class="ion-align-self-end">
                  <ion-button
                    class="signup-btn"
                    fill="clear"
                    router-link="/login"
                  >Already have an account?</ion-button>
                </ion-col>
              </ion-row>
            </div>

            <div class="ion-text-center ion-padding-top login-buttons">
              <ion-button
                expand="full"
                color="primary"
                type="submit"
                :disabled="vv.$anyError"
              >REGISTER</ion-button>
            </div>
          </ion-col>
        </ion-row>
      </form>
    </ion-content>
  </ion-page>
</template>

<script>
import { supabase } from "../supabase";
import { useVuelidate } from "@vuelidate/core";

import {
  required,
  // eslint-disable-next-line
  email,
  // eslint-disable-next-line
  minLength,
  // eslint-disable-next-line
  sameAs
} from "../utils/validation-tranlations";

import { defineComponent, reactive, toRef } from "vue";
import { useI18n } from "vue-i18n";
import {
  mail as mailIcon,
  lockClosed as lockIcon,
  alertCircleOutline as alertIcon
} from "ionicons/icons";

import {
  IonPage,
  IonList,
  IonItem,
  IonInput,
  IonIcon,
  IonButton,
  IonRow,
  IonCol,
  IonContent,
  toastController,
  loadingController,
  menuController
} from "@ionic/vue";

export default defineComponent({
  name: "LoginPage",
  components: {
    IonPage,
    IonContent,
    IonRow,
    IonCol,
    IonList,
    IonItem,
    IonIcon,
    IonInput,
    IonButton
  },
  setup() {
    const { t } = useI18n({
      inheritLocale: true
    });

    const fform = reactive({
      email: "",
      password1: "",
      password2: ""
    });

    const rules = {
      emailAddress: { required, email },
      password1: { required, minLenght: minLength(8) },
      password2: {
        required,
        minLenght: minLength(8),
        sameAs: sameAs(toRef(fform, "password1"), "password")
        // sameAs: sameAs(toRef(fform, "password1"), "password")
      }
    };

    const vv = useVuelidate(rules, {
      password1: toRef(fform, "password1"),
      password2: toRef(fform, "password2"),
      emailAddress: toRef(fform, "emailAddress")
    });

    const handleSignup = async () => {
      vv.value.$touch();
      if (vv.value.$invalid) return;
      console.log(fform);

      const loader = await loadingController.create({});
      const toast = await toastController.create({ duration: 5000 });

      try {
        await loader.present();
        const { error } = await supabase.auth.signUp({
          email: fform.emailAddress,
          password: fform.password1
        });

        if (error) throw error;

        toast.message = "Registrazione Effettuata controlla la mail e completa";
        await toast.present();
      } catch (error) {
        toast.message = error.error_description || error.message;
        await toast.present();
      } finally {
        await loader.dismiss();
      }
    };

    return {
      t,
      handleSignup,
      vv,
      mailIcon,
      lockIcon,
      alertIcon
    };
  },
  async ionViewDidEnter() {
    console.log("Home page did enter");
    menuController.enable(false);
  }
});
</script>
<style lang="scss" scoped>
@media (min-width: 676px) {
  .login-content {
    max-width: 550px;
  }
}

ion-content {
  --background: linear-gradient(#f4f5f8, #4786ef);
}

ion-grid {
  height: 100%;
}
ion-row {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  ion-list {
    color: #ff00;
  }
}

ion-item {
  --ion-item-background: transparent;
}

ion-list {
  --ion-item-background: transparent;
}

ion-input {
  --background: transparent;
  --color: #ffffff;
}

ion-icon {
  margin: 0 5px;
}

.login-buttons {
  ion-button {
    font-size: 0.7em;
  }
}

.error-container {
  .error-message {
    margin: calc(var(--page-margin) / 2) 0px;
    display: flex;
    align-items: center;
    color: var(--ion-color-danger);
    font-size: 14px;

    ion-icon {
      padding-inline-end: calc(var(--page-margin) / 2);
    }
  }
}

.other-auth-options-row {
  justify-content: space-between;
  //align-items: center;

  .forgot-btn {
    --color: var(--ion-color-medium);
    margin: 0px;

    &:focus {
      outline: none;
    }
  }

  .signup-btn {
    --color: var(--ion-color-secondary);
    margin: 0px;

    &:focus {
      outline: none;
    }
  }
}
</style>