<template>
  <ion-app class="md ion-page">
    <ion-split-pane content-id="main-content">
      <app-menu content-id="main-content"></app-menu>
      <ion-router-outlet id="main-content" />
    </ion-split-pane>
  </ion-app>
</template>

<script>
import { defineComponent } from "vue";
import AppMenu from "./components/AppMenu.vue";
import { store } from "./store";
import { supabase } from "./supabase";
import {
  IonApp,
  IonRouterOutlet,
  useIonRouter,
  IonSplitPane,
  menuController
} from "@ionic/vue";

export default defineComponent({
  name: "App",
  components: {
    IonApp,
    IonRouterOutlet,
    IonSplitPane,
    AppMenu
  },
  setup() {
    // eslint-disable-next-line
    const router = useIonRouter();
    store.user = supabase.auth.user() ?? {};

    supabase.auth.onAuthStateChange((_, session) => {
      console.log("onAuthStateChange:", session?.user);
      if (session?.user) {
        store.user = session?.user;
        store.menu = true;
        console.log(("route->about with user", store.user));
        menuController.enable(true);
        router.replace("/about");
      } else {
        console.log("route->login");
        router.push("/login");
      }
    });

    return { store };
  }
});
</script>