<template>
  <main-layout pageTitle="Home">
    <ion-content>
      <ion-card>
        <ion-item>
          <ion-icon :icon="alertIcon" slot="start"></ion-icon>
          <ion-label>ion-item in a card, icon left, button right</ion-label>
          <ion-button fill="outline" slot="end">View</ion-button>
        </ion-item>

        <ion-card-content>
          This is content, without any paragraph or header tags,
          within an ion-card-content element.
          {{ t('home.main') }}
        </ion-card-content>
      </ion-card>
      <ion-card>
        <ion-item>
          <ion-icon :icon="alertIcon" slot="start"></ion-icon>
          <ion-label>Session Information</ion-label>
          <ion-button fill="outline" slot="end">Debug</ion-button>
        </ion-item>
        <ion-card-content>
          <pre>
           {{ session }}
        </pre>
        </ion-card-content>
      </ion-card>
    </ion-content>
  </main-layout>
</template>
<script>
import { defineComponent, ref } from "vue";
import { supabase } from "../supabase";
import { useI18n } from "vue-i18n";
import { alertCircleOutline as alertIcon } from "ionicons/icons";

import {
  IonCard,
  IonContent,
  IonCardContent,
  IonItem,
  IonIcon,
  IonLabel,
  IonButton
} from "@ionic/vue";

export default defineComponent({
  name: "HomePage",
  components: {
    IonContent,
    IonCard,
    IonCardContent,
    IonItem,
    IonLabel,
    IonButton,
    IonIcon
  },
  setup() {
    const session = ref(supabase.auth.session());
    const { t } = useI18n({
      inheritLocale: true
    });

    //
    return { session, t, alertIcon };
  }
});
</script>