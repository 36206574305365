import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";

import { IonicVue } from "@ionic/vue";

/* Core CSS required for Ionic components to work properly */
import "@ionic/vue/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/vue/css/normalize.css";
import "@ionic/vue/css/structure.css";
import "@ionic/vue/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/vue/css/padding.css";
import "@ionic/vue/css/float-elements.css";
import "@ionic/vue/css/text-alignment.css";
import "@ionic/vue/css/text-transformation.css";
import "@ionic/vue/css/flex-utils.css";
import "@ionic/vue/css/display.css";

import MainLayout from "./components/MainLayout.vue";
import { store } from "./store";

//import { createI18n } from "vue-i18n";

/* Theme variables */
import "./theme/variables.scss";

import { i18n } from "./utils/i18n";
/*
import it from "./locales/it.json";
import en from "./locales/en.json";

const i18n = createI18n({
  legacy: false,
  locale: "it",
  fallbackLocale: "it",
  messages: {
    it: it,
    en: en,
  },
});
*/

const app = createApp(App).use(IonicVue).use(i18n).use(router);

router.beforeEach((to, from, next) => {
  if (to.meta.requiresAuth && !store?.user?.id) {
    next({ name: "Login" });
  } else {
    next();
  }
});

//Registriamo il componente nostro in tutta l'app
app.component("main-layout", MainLayout);
router.isReady().then(() => {
  app.mount("#app");
});

i18n.locale = "en";
