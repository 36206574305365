<template>
  <main-layout pageTitle="About">
    <ion-content class="about-content">
      <div class="ion-text-justify">
        <h2 class="about-title ion-padding-bottom">{{ t('about.main.title') }}</h2>

        <p class="about-text">{{ t('about.main.body') }}</p>
      </div>
    </ion-content>
  </main-layout>
</template>
<script>
import { IonContent } from "@ionic/vue";
import { useI18n } from "vue-i18n";

export default {
  components: {
    // eslint-disable-next-line
    IonContent
  },
  setup() {
    const { t } = useI18n({
      inheritLocale: true
    });

    return { t };
  },
  data() {
    return {
      recentList: [
        {
          name: "Savings Account",
          cost: "R3 400",
          description: "Available Balance",
          // icon: repeat,
          color: "#36AEEA"
        }
      ]
    };
  }
};
</script>
<style lang="scss" scoped>
.about-content {
  --background: var(--page-background);
  --padding-start: 20px;
  --padding-end: 20px;
  --padding-top: 30px;
  --padding-bottom: 20px;

  .about-title {
    color: var(--ion-color-secondary);
    margin: var(--page-margin) 0px calc(var(--page-margin) / 2);
    text-align: center;
  }

  .about-text {
    color: var(--ion-color-medium);
    margin: calc(var(--page-margin) / 2) 0px var(--page-margin);
    font-size: 14px;
    line-height: 20px;
    text-align: justify;
  }
}

ion-content {
  --margin-bottom: 10px;
  --margin-end: 10px;
  --margin-start: 20px;
  --margin-top: 20px;
}
</style>