<template>
  <ion-page>
    <ion-content padding="true" scroll="false">
      <form @submit.prevent="handleLogin" novalidate>
        <ion-row>
          <ion-col class="login-content">
            <div class="ion-padding">
              <h1>uTwist NFT</h1>
              <p>{{ t('login.main') }}</p>
            </div>
            <ion-list>
              <ion-item>
                <ion-icon :icon="mailIcon"></ion-icon>
                <ion-input
                  type="email"
                  name="emailAddress"
                  placeholder="Login Email"
                  v-model="vv.emailAddress.$model"
                />
              </ion-item>
              <div class="error-container" v-if="vv.emailAddress.$invalid">
                <div class="error-message">
                  <ion-icon :icon="alertIcon"></ion-icon>
                  <span>{{ vv?.emailAddress?.$errors[0]?.$message }}</span>
                </div>
              </div>
              <ion-item>
                <ion-icon :icon="lockIcon"></ion-icon>
                <ion-input
                  type="password"
                  name="password"
                  placeholder="Password"
                  v-model="vv.password.$model"
                />
              </ion-item>
              <div class="error-container" v-if="vv.password.$invalid">
                <div class="error-message">
                  <ion-icon :icon="alertIcon"></ion-icon>
                  <span>{{ vv?.password?.$errors[0]?.$message }}</span>
                </div>
              </div>
            </ion-list>

            <div class="other-auth-options-row">
              <ion-row>
                <ion-col class="floating">
                  <ion-button
                    class="forgot-btn"
                    fill="clear"
                    xx="['/auth/forgot-password']"
                  >Forgot Password?</ion-button>
                </ion-col>
                <ion-col class="ion-align-self-end">
                  <ion-button class="signup-btn" fill="clear" router-link="/signup">Sign Up!</ion-button>
                </ion-col>
              </ion-row>
            </div>

            <div class="ion-text-center ion-padding-top login-buttons">
              <ion-button expand="full" color="primary" type="submit" :disabled="vv.$anyError">LOGIN</ion-button>
              <ion-button expand="full" @click="handleFacebookLogin" color="primary">LOGIN FACEBOOK</ion-button>
            </div>
          </ion-col>
        </ion-row>
      </form>
    </ion-content>
  </ion-page>
</template>

<script>
import { supabase } from "../supabase";
import { useI18n } from "vue-i18n";
import { useVuelidate } from "@vuelidate/core";
import { required, email } from "@vuelidate/validators";
import { defineComponent, reactive, toRef } from "vue";
import {
  mail as mailIcon,
  lockClosed as lockIcon,
  alertCircleOutline as alertIcon
} from "ionicons/icons";

import {
  //IonContent,
  // IonHeader,
  //IonPage,
  // IonTitle,
  // IonToolbar,
  IonPage,
  IonList,
  IonItem,
  //IonLabel,
  IonInput,
  IonIcon,
  IonButton,
  IonRow,
  IonCol,
  IonContent,
  toastController,
  loadingController,
  menuController
} from "@ionic/vue";

export default defineComponent({
  name: "LoginPage",
  components: {
    IonPage,
    IonContent,
    IonRow,
    IonCol,
    //IonHeader,
    // IonPage,
    // IonTitle,
    // IonToolbar,
    IonList,
    IonItem,
    IonIcon,
    //IonLabel,
    IonInput,
    IonButton
  },
  setup() {
    //const email = ref("");
    //const password = ref("");

    const fform = reactive({
      email: "",
      password: ""
    });

    const rules = {
      emailAddress: { required, email },
      password: { required }
    };

    const vv = useVuelidate(rules, {
      password: toRef(fform, "password"),
      emailAddress: toRef(fform, "emailAddress")
    });

    const { t } = useI18n({
      inheritLocale: true
    });

    const handleLogin = async () => {
      vv.value.$touch();
      if (vv.value.$invalid) return;
      console.log(fform);

      const loader = await loadingController.create({});
      const toast = await toastController.create({ duration: 5000 });

      try {
        await loader.present();
        const { error } = await supabase.auth.signIn({
          email: fform.emailAddress,
          password: fform.password
        });

        if (error) throw error;

        toast.message = "Login Effettuato";
        await toast.present();
      } catch (error) {
        toast.message = error.error_description || error.message;
        await toast.present();
      } finally {
        await loader.dismiss();
      }
    };

    const handleFacebookLogin = async () => {
      const loader = await loadingController.create({});
      const toast = await toastController.create({ duration: 5000 });

      try {
        await loader.present();
        const { error } = await supabase.auth.signIn({ provider: "facebook" });

        if (error) throw error;

        toast.message = "Check your email for the login link!";
        await toast.present();
      } catch (error) {
        toast.message = error.error_description || error.message;
        await toast.present();
      } finally {
        await loader.dismiss();
      }
    };

    return {
      t,
      handleLogin,
      handleFacebookLogin,
      vv,
      mailIcon,
      lockIcon,
      alertIcon
    };
  },
  async ionViewDidEnter() {
    console.log("Home page did enter");
    menuController.enable(false);
  }
});
</script>
<style lang="scss" scoped>
@media (min-width: 676px) {
  .login-content {
    max-width: 550px;
  }
}

ion-content {
  --background: linear-gradient(#f4f5f8, #4786ef);
}

ion-grid {
  height: 100%;
}
ion-row {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  ion-list {
    color: #ff00;
  }
}

ion-item {
  --ion-item-background: transparent;
}

ion-list {
  --ion-item-background: transparent;
}

ion-input {
  --background: transparent;
  --color: #ffffff;
}

ion-icon {
  margin: 0 5px;
}

.login-buttons {
  ion-button {
    font-size: 0.7em;
  }
}

.error-container {
  .error-message {
    margin: calc(var(--page-margin) / 2) 0px;
    display: flex;
    align-items: center;
    color: var(--ion-color-danger);
    font-size: 14px;

    ion-icon {
      padding-inline-end: calc(var(--page-margin) / 2);
    }
  }
}

.other-auth-options-row {
  justify-content: space-between;
  //align-items: center;

  .forgot-btn {
    --color: var(--ion-color-medium);
    margin: 0px;

    &:focus {
      outline: none;
    }
  }

  .signup-btn {
    --color: var(--ion-color-secondary);
    margin: 0px;

    &:focus {
      outline: none;
    }
  }
}
</style>